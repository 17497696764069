<template>
  <!-- withdraw -->
  <div class="deposit-wrap">
    <WithdrawCpt></WithdrawCpt>
  </div>
</template>
<script>
import WithdrawCpt from '@/components/withdraw/index.vue'

export default {
  name: 'Withdraw',
  components: { WithdrawCpt },
  computed: {
  },
  methods: {
  }
}
</script>
