<template>
  <div class="crypto-wrap">
    <CoinList @callback="coinListCallback" :searchValue="searchValue"></CoinList>
    <van-popup v-model="popupShow" position="bottom">
      <div class="withdraw-list background-color-4">
        <van-nav-bar :title="$t('withdraw.withdraw')" left-text=" " >
          <van-icon name="cross" slot="right" />
        </van-nav-bar>
        <div class="chooseNetwork-main">
          <ul class="list-box padding20">
            <li class="padding25 background-color-2 margin-top20" @click="onConfirm('crypto')">
             <div>
                <p class="main-text-1">
                  {{ $t('withdraw.methodTitle1') }}
                </p>
                <p class="main-text-3">
                  {{ $t('withdraw.methodTip1') }}
                </p>
             </div>
            </li>
            <li class="padding25 background-color-2 margin-top20" @click="onConfirm('other')">
              <div>
                <p class="main-text-1">
                  {{ $t('withdraw.methodTitle2') }}
                </p>
                <p class="main-text-3">
                  {{ $t('withdraw.methodTip2') }}
                </p>
             </div>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue'
import { Search, Tab, Tabs, Icon, Popup } from 'vant'
import CoinList from '@/components/withdraw/coinList/index.vue'
Vue.use(Tab).use(Tabs)
Vue.use(Search)
Vue.use(Icon)
Vue.use(Popup)

export default {
  name: 'Crypto',
  components: { CoinList },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      popupShow: false,
      historyList: [
        'BTC', 'ETH', 'DOGE', 'USDT', 'BUSD', 'AVAX', 'ATOM', '1INCH'
      ],
      trendingList: [
        'BTC', 'ETH', 'DOGE', 'USDT', 'BUSD', 'AVAX', 'ATOM', '1INCH'
      ],
      selectCoin: {}
    }
  },
  methods: {
    back () {
      this.$router.back()
    },
    coinListCallback (item) {
      console.log(item)
      this.selectCoin = item
      // this.popupShow = true
      this.onConfirm('crypto')
    },
    clickCoin (item) {
      this.selectCoin = item
      this.popupShow = true
    },
    onConfirm (value) {
      console.log(value)
      const handleObj = {
        crypto: () => {
          this.$router.push({
            path: '/networkWithdraw',
            query: {
              coinName: this.selectCoin.name,
              label: this.selectCoin.label
            }
          })
        },
        other: () => {

        }
      }
      const fn = handleObj[value]
      try {
        fn()
      } catch (error) {
      }
    }
  }
}
</script>
<style lang="less">
.crypto-wrap{
  box-sizing: border-box;
}
.history-card,.trending-card{
  .history-list,.trending-list{
    padding-top: 0;
    li{
      padding: 0.66667vw;
      border-radius: 0.66667vw;
    }
  }
}
</style>
