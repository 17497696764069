<template>
  <!-- wallets-funding/资金账户 -->
  <div class="withdraw-coin-list">
    <div>
      <van-row>
        <van-col :span="tab.span"  v-for="tab in tabs" :key="tab.value" class="ub" :class="tab.class">
          <div v-for="(tabText, tabTextIdx) in tab.label" :key="tabTextIdx" class="ub ub-cen tab-text">
            <div class="ub ub-cen tab-text" @click="sortType(tabText)">
              <span v-if="tabTextIdx != 0" class="font12 font-weight400"> / </span>
              <span class="margin-right8 main-text-3">
                {{ $t(`withdraw.${tabText}`) }}
              </span>
              <img :src="iconStatus[tabsStatus[tabText]]"/>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <ul class="coin-list margin-top20">
      <li v-for="(item, index) in showCoinList"  @click="clickCoin(item)" class="ub padding12 ub-btw" :key="index">
        <div class="ub left-box">
          <div class="icon-box margin-right12"></div>
          <div>
            <div class="label-box font14">
              {{ item.label }}
            </div>
            <div class="name-box font-weight400 main-text-3 ub ub-btw font12">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="text-r">
          <div class="label-box font14 main-text-1">
            {{ item.value }}
          </div>
          <div class="name-box font10 main-text-3">
            ≈ {{ item.legalUnit }} {{ item.legal }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue'
import { Row, Col, List } from 'vant'
Vue.use(List)
Vue.use(Row).use(Col)

const sortAll = require('@/assets/images/system/sort-all.svg')
const sortDown = require('@/assets/images/system/sort-down.svg')
const sortUp = require('@/assets/images/system/sort-down.svg')
export default {
  components: { },
  computed: {
  },
  watch: {
    searchValue () {
      let list = []
      console.log(this.searchValue)
      if (this.searchValue) {
        list = this.coinlists.filter((item) => {
          return item.name.indexOf(this.searchValue) > -1
        })
      } else {
        list = [...this.coinlists]
      }
      this.handlerCoinlists = [...list]
      this.showCoinList = list
    }
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showCoinList: [],
      iconStatus: {
        pending: sortAll,
        up: sortUp,
        down: sortDown
      },
      tabsStatus: {
        // pending up down
        token: 'pending',
        balance: 'pending'
      },
      tabs: [
        { label: ['token'], value: 2, span: 12, cls: 'token', class: '' },
        { label: ['balance'], value: 3, span: 12, cls: 'balance', class: 'ub-tope' }
      ],
      handlerCoinlists: [],
      coinlists: [
      ]
    }
  },
  mounted () {
    this.coinlists[0] = {
      icon: '',
      label: 'USDT',
      name: 'TetherUS',
      value: this.$store.state.totalAssets.available_amount,
      legal: this.$store.state.totalAssets.available_amount,
      legalUnit: '$'
    }
    this.showCoinList = [...this.coinlists]
    this.handlerCoinlists = [...this.coinlists]
  },
  methods: {
    sortType (key) {
      const changeStatusObj = {
        pending: 'down',
        down: 'up',
        up: 'pending'
      }
      const keyStatus = changeStatusObj[this.tabsStatus[key]]
      this.tabsStatus = {
        token: 'pending',
        balance: 'pending'
      }
      this.tabsStatus[key] = keyStatus
      const sortObj = {
        target: key,
        status: this.tabsStatus[key]
      }
      this.sortFn(sortObj)
    },
    sortFn (sortType) {
      const sortTargetHandlerObj = {
        token: () => {
          if (sortType.status === 'pending') {
            let list = []
            console.log(this.searchValue)
            if (this.searchValue) {
              list = this.coinlists.filter((item) => {
                return item.name.indexOf(this.searchValue) > -1
              })
            } else {
              list = [...this.coinlists]
            }
            this.handlerCoinlists = [...list]
          }
          if (sortType.status === 'down') {
            this.handlerCoinlists = this.handlerCoinlists.sort((a, b) => {
              return b.legal - a.legal
            })
          }
          if (sortType.status === 'up') {
            this.handlerCoinlists = this.handlerCoinlists.sort((a, b) => {
              return a.legal - b.legal
            })
          }
          this.showCoinList = [...this.handlerCoinlists]
          console.log(this.showCoinList, 'this.showCoinList')
        },
        balance: () => {
          if (sortType.status === 'pending') {
            let list = []
            console.log(this.searchValue)
            if (this.searchValue) {
              list = this.coinlists.filter((item) => {
                return item.name.indexOf(this.searchValue) > -1
              })
            } else {
              list = [...this.coinlists]
            }
            this.handlerCoinlists = [...list]
          }
          if (sortType.status === 'down') {
            this.handlerCoinlists = this.handlerCoinlists.sort((a, b) => {
              return parseInt(b.name[0], 36) - parseInt(a.name[0], 36)
            })
          }
          if (sortType.status === 'up') {
            this.handlerCoinlists = this.handlerCoinlists.sort((a, b) => {
              return parseInt(a.name[0], 36) - parseInt(b.name[0], 36)
            })
          }
          this.showCoinList = [...this.handlerCoinlists]
        }
      }
      sortTargetHandlerObj[sortType.target]()
    },
    clickCoin (item) {
      this.$emit('callback', item)
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.withdraw-coin-list{
  .coin-list{
    width: 100%;
    box-sizing: border-box;
    li{
      margin-bottom: 16px;
      border-radius: 16px;
      border: 1px solid #ECEAEF;
      box-sizing: border-box;
      width: 100%;
      .left-box{
        align-items: center;
      }
      .icon-box{
        width: 48px;
        height: 48px;
        background-color: #1C1B1E;
        opacity: 0.3;
        border-radius: 50%;
      }
    }
  }
}
</style>
